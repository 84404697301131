import React from "react";
import _ from 'lodash'

export const ListDocuments = (formContext, codeProduit = "") => {
    let listDoc = [];
    switch (formContext){
        case 'AN':
            return ListDocumentsAN(codeProduit);

        case 'RES':
            return ListDocumentsRES();

        default:
            break
    }
}
const ListDocumentsAN = (codeProduit = "") => {
    const listDoc = [
        {
            libelle: "Dispositions Particulières",
            code: "A"
        },
        {
            libelle: "Fiche conseil",
            code: "B"
        },
        {
            libelle: "RIB",
            code: "C"
        },
        {
            libelle: "Mandat SEPA",
            code: "D"
        },
        {
            libelle: "Carte grise",
            code: "E"
        },
        {
            libelle: "Carte grise barrée",
            code: "F"
        },
        {
            libelle: "Attestation de Bridage",
            code: "BB"
        },
        {
            libelle: "Certificat provisoire d'immatriculation",
            code: "G"
        },
        {
            libelle: "Certificat de cession",
            code: "H"
        },
        {
            libelle: "Attestation Conduite 2 roues recto",
            code: "I"
        },
        {
            libelle: "Attestation Conduite 2 roues verso",
            code: "J"
        },
        {
            libelle: "Justificatif assurance 125cm3 - 3 roues",
            code: "JA"
        },
        {
            libelle: "Pièce identité Recto",
            code: "K"
        },
        {
            libelle: "Pièce identité Verso",
            code: "B7"
        },
        {
            libelle: "Titre de séjour recto",
            code: "L"
        },
        {
            libelle: "Titre de séjour verso",
            code: "M"
        },
        {
            libelle: "Ancien permis de conduire recto",
            code: "N"
        },
        {
            libelle: "Ancien permis de conduire verso",
            code: "O"
        },
        {
            libelle: "Nouveau permis de conduire recto",
            code: "P"
        },
        {
            libelle: "Nouveau permis de conduire verso",
            code: "Q"
        },
        {
            libelle: "BSR recto",
            code: "R"
        },
        {
            libelle: "BSR verso",
            code: "S"
        },
        {
            libelle: "Email",
            code: "T"
        },
        {
            libelle: "Mandat Hamon",
            code: "U"
        },
        {
            libelle: "Facture achat du véhicule",
            code: "V"
        },
        {
            libelle: "Relevé d'informations",
            code: "Z"
        },
        {
            libelle: "Certificat d'exam permis de conduire",
            code: "AA"
        },
        {
            libelle: "Autres",
            code: "B0"
        },
        {
            libelle: "Chèque",
            code: "B1"
        },
        {
            libelle: "Paiement",
            code: "B2"
        },
        {
            libelle: "Carte grise verso",
            code: "B3"
        },
        {
            libelle: "Gravage anti-vol",
            code: "B4"
        },
        {
            libelle: "Justificatif LR",
            code: "B5"
        },
        {
            libelle: "Facture",
            code: "B6"
        }
    ]

    /* Ajout des Relevé d'informations auto et moto pour les produits qui ne sont pas PVK et PVE */
    if (!_.includes(['7311_PVK', '7311_PVE', '7311_SC'], codeProduit)) {
        listDoc.push({
            libelle: "Relevé d'informations auto",
            code: "W"
        })
        listDoc.push({
            libelle: "Relevé d'informations moto",
            code: "X"
        })
    }

    return listDoc
}

const ListDocumentsRES = () => {
    return [
        {
            libelle: "Carte grise",
            code: "E"
        },
        {
            libelle: "Certificat de cession",
            code: "H"
        },
        {
            libelle: "Email",
            code: "T"
        },
        {
            libelle: "Autres",
            code: "B0"
        },
    ]
}
